<template>
    <div>
        <!-- Cards -->
        <div class="card mb-3" v-if="$auth.hasSomePermission('read:bank_accounts', 'read:credit_cards') ">
            <div class="card-header mt-2 pb-0">
                <h5 class="fs-0">{{ customer.name }} Accounts</h5>
            </div>
            <div class="card-body">
                <div class="form-group d-inline-flex flex-column">
                    <div class="position-relative">
                        <div class="form-check form-check-inline" v-if="$auth.hasPermissions('read:credit_cards')">
                            <input v-model="paymentType"
                                   id="paymentMethodCardInput"
                                   class="form-check-input"
                                   type="radio"
                                   value="card">
                            <label for="paymentMethodCardInput" class="form-check-label">
                                Credit Card
                            </label>
                        </div>
                        <div class="form-check form-check-inline" v-if="$auth.hasPermissions('read:bank_accounts')">
                            <input v-model="paymentType"
                                   id="paymentMethodBankInput"
                                   class="form-check-input"
                                   type="radio"
                                   value="bank">
                            <label for="paymentMethodBankInput" class="form-check-label">
                                Bank Account
                            </label>
                        </div>
                    </div>
                </div>
                <transition appear name="fade" mode="out-in">
                    <!-- If radio buttons are hidden, paymentType is still 'card' by default. -->
                    <div v-if="paymentType === 'card'" :key="'card'">
                        <CustomerCreditCards :customer-id="customer.id"
                                             :customer-prefill-name="customer.name"
                                             :customer-prefill-email="customer.email"
                                             @loadingError="braintreeError"
                                             @submissionError="braintreeError" />
                    </div>
                    <div v-else-if="paymentType === 'bank'" :key="'bank'">
                        <CustomerBankAccounts :customer-id="customer.id"
                                              :customer-prefill-name="customer.name"
                                              :customer-prefill-email="customer.email"
                                              @loadingError="braintreeError"
                                              @submissionError="braintreeError" />
                    </div>
                </transition>
            </div>
        </div>

        <!-- Users -->
        <div class="card" v-if="$auth.hasPermissions('read:customers.users')">
            <div class="card-header">
                <div class="row align-items-center justify-content-between">
                    <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                        <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">{{ customer.name }} Users</h5>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <VTable :data="customer.users"
                        :initial-sort="{ field: 'created', order: false }"
                        :page-size="20">
                    <template #header="{ sort, sortBy }">
                        <tr>
                            <th />
                            <VTableHeader field="full_name" v-bind="{ sort, sortBy }">
                                Name
                            </VTableHeader>
                            <VTableHeader field="email" v-bind="{ sort, sortBy }">
                                Email
                            </VTableHeader>
                            <VTableHeader field="created" v-bind="{ sort, sortBy }">
                                Date Added
                            </VTableHeader>
                            <th />
                        </tr>
                    </template>
                    <template #row="{ item: customerUser }">
                        <tr :key="customerUser.id" class="btn-reveal-trigger">
                            <td class="py-2 align-middle white-space-nowrap line-height-1">
                                <div class="avatar avatar-s">
                                    <div class="avatar-name rounded-circle">
                                        <span class="fas fa-user" />
                                    </div>
                                </div>
                            </td>
                            <td class="py-2 align-middle">{{ customerUser.first_name }} {{ customerUser.last_name }}</td>
                            <td class="py-2 align-middle">{{ customerUser.email }}</td>
                            <td class="py-2 align-middle">{{ customerUser.created | formatISODate('PP') }}</td>
                            <td class="py-2 align-middle white-space-nowrap">
                                <div class="dropdown text-sans-serif"
                                     v-if="$auth.hasSomePermission('update:customers.users')">
                                    <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal mr-3"
                                            type="button"
                                            data-toggle="dropdown">
                                        <span class="fas fa-ellipsis-h fs--1" />
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right border py-0">
                                        <div class="bg-white py-2">
                                            <button class="dropdown-item"
                                                    @click="resetUserPassword(customerUser)"
                                                    v-if="$auth.hasPermissions('update:customers.users')">
                                                Reset Password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </VTable>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomerBankAccounts from '@/components/CustomerBankAccounts';
    import CustomerCreditCards from '@/components/CustomerCreditCards';
    import { resetCustomerUserPassword } from '@/services/CustomerService';
    import VTable from '../VTable';
    import VTableHeader from '../VTableHeader.vue';

    export default {
        name: 'CustomerList',
        components: {
            CustomerCreditCards,
            CustomerBankAccounts,
            VTable,
            VTableHeader,
        },
        props: {
            customer: {
                type: Object,
                required: true,
            },
        },
        data: vm => ({
            paymentType: vm.getDefaultPaymentType(),
        }),
        methods: {
            /**
             * Gets the default selected payment method based on the user's permissions.
             * Defaults to 'card' if the user has card permissions, 'bank' if they have
             * bank but not card permissions, and null otherwise.
             */
            getDefaultPaymentType() {
                if (this.$auth.hasPermissions('read:credit_cards')) {
                    return 'card';
                }
                if (this.$auth.hasPermissions('read:bank_accounts')) {
                    return 'bank';
                }

                return null;
            },
            async resetUserPassword(user) {
                try {
                    await resetCustomerUserPassword(user.id);
                    this.$alerts.message('Password Reset Sent',
                                         'A password reset email has been sent to the user.');
                } catch {
                    this.$alerts.danger('Unable to Send Password Reset Email',
                                        'Please contact support if this problem persists.');
                }
            },
            /**
             * Alert a user when Braintree has failed.
             * @param {string} message Explains the error to the user
             */
            braintreeError(message) {
                this.$alerts.danger(message, 'Please contact support if this problem persists.');
            },
        },
    };
</script>

<style scoped>
    .fade-enter-to, .fade-leave {
        transition: all 0.3s;
        overflow: hidden;
    }

    .fade-enter, .fade-leave-to {
        overflow: hidden;
        opacity: 0;
    }
</style>
